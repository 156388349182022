body {
  margin: 0;
  min-height: 100vh;
}

html,
body {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  font-family: "Lato", sans-serif;
}
*:focus {
  outline: none;
}

#root {
  height: 100%;
  width: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input:focus {
  outline: none;
}
.hidden {
  display: none;
}

.ReactCollapse--collapse {
  transition: height 500ms;
}

.card {
  border-color: #00000026;
  border-style: solid;
  border-width: 0.5px;
  border-radius: 1rem;
  background-color: #ffffff;
}

.border {
  border-color: #00000026;
  border-style: solid;
  border-width: 0.5px;
}
.borderBottom {
  border-color: #00000026;
  border-style: solid;
  border-bottom-width: 0.5px;
}

.borderTop {
  border-color: #ffffff33;
  border-style: solid;
  border-top-width: 0.5px;
}

.borderRight {
  border-color: #00000026;
  border-style: solid;
  border-right-width: 0.5px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.shadow {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
}

.borderToggle {
  border: 0.5px solid rgba(0, 0, 0, 0.08);
}

.z-1 {
  z-index: 1;
}

.flex-2 {
  flex: 2;
}
.flex-3 {
  flex: 3;
}
.flex-4 {
  flex: 4;
}
.flex-5 {
  flex: 5;
}
